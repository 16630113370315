<template>
  <div class="team">
    <div class="duptitle">服务团队</div>
    <div class="numberpeople">
      总计<span class="people-text">&nbsp;{{ TeamTotal }}&nbsp;</span>家
    </div>
    <div class="team-box" ref="chartDom"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { getserveData } from "@/services/basicdata.js";
export default {
  name: "Team",

  async created() {
    let team = await getserveData();
    this.TeamData = team;
    this.pieChart();
  },
  data() {
    return {
      masterCeremonies: 0,
      photographer: 0,
      makeupArtist: 0,
      cameraman: 0,
      TeamTotal: 0,

      TeamData: [],
    };
  },

  methods: {
    pieChart() {
      var chartDom = this.$refs.chartDom;
      var myChart = echarts.init(chartDom);
      var option;
      const dataList = [
        {
          value: this.masterCeremonies,
          name: `司仪`,
        },
        {
          value: this.photographer,
          name: `摄影师`,
        },
        {
          value: this.makeupArtist,
          name: `化妆师`,
        },
        {
          value: this.cameraman,
          name: `摄像师`,
        },
      ];
      option = {
        color: ["#BEE5FB", "#3254DD", "#3CD495", "#48E5E5"],
        legend: {
          orient: "vertical",
          selectedMode: false,
          left: "60%",
          top: "28%",
          textStyle: {
            rich: {
              name: {
                fontSize: 14,
                align: "left",
                padding: [10, 0, 10, 5],
                width: 100,
                color: "#ffff",
              },
              value: {
                fontSize: 14,
                align: "right",
                padding: [10, 0, 10, -20],
                color: "#ffff",
              },
            },
          },
          icon: "rect",
          itemWidth: 10,
          itemHeight: 10,
          formatter: (name) => {
            let value = dataList.filter((item) => item.name === name)[0].value;
            return "{name|" + name + "}{value|" + value + "}";
          },
        },
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            type: "pie",
            radius: "60%",
            label: {
              psotion: "inner",
              show: false,
            },
            center: ["30%", "50%"],
            data: dataList,
          },
        ],
      };
      myChart.setOption(option);
    },
  },
  watch: {
    TeamData: {
      handler(val) {
        val.forEach((item) => {
          switch (item.serveName) {
            case "司仪":
              this.masterCeremonies = item.serveNum;
              break;
            case "摄影师":
              this.photographer = item.serveNum;
              break;
            case "化妆师":
              this.makeupArtist = item.serveNum;
              break;
            case "摄像师":
              this.cameraman = item.serveNum;
              break;
          }
          this.TeamTotal += item.serveNum;
        });
        this.pieChart();
      },
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped>
.team {
  position: absolute;
  top: 69%;
  left: 0;
  height: 29%;
  width: 100%;
  .numberpeople {
    color: #fff;
    position: absolute;
    top: 22%;
    right: 20%;
    .people-text {
      font-size: 25px;
    }
  }
  .team-box {
    display: flex;
    width: 100%;
    height: 86%;
  }
}
</style>
