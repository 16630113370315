<script>
import * as echarts from 'echarts'
import chinaMapJson from '@/mapJson/china.json'
import { mapState } from 'vuex'

// echarts.registerMap('china', chinaMapJson)

export default {
  render(createElement) {
    return createElement('div', {
      attrs: {
        id: 'main'
      },
      style: {
        height: '400px',
        width: '700px',
        margin: 'auto'
      }
    })
  },
  created() {},
  watch: {
    data() {
      this.initEchart()
    }
  },
  methods: {
    disposeMapData() {
      console.log('cityData', this.data.cityData)
      let mapJson = chinaMapJson
      console.log('mapJson', mapJson)
      let cityNameArr = []
      this.data.cityData.forEach(item => {
        cityNameArr.push(item.cityName)
      })
      // mapJson.features = chinaMapJson.features.filter(item => cityNameArr.indexOf(item.properties.name) !== -1)
      mapJson.features.forEach(item => {
        if (cityNameArr.indexOf(item.properties.name) === -1) item.properties.name = ''
      })
      console.log('mapJson', mapJson)
      echarts.registerMap('china', mapJson)
    },
    // 操作接口数据
    updList(list) {
      return list.map(v => {
        return {
          ...v,
          name: v.cityName
        }
      })
    },
    // 初始化图表
    initEchart() {
      this.disposeMapData()
      // echarts.registerMap('china', chinaMapJson)
      const dataList = this.updList(this.data.cityData)
      console.log('dataList', dataList)
      const self = this
      var myChart = echarts.init(document.getElementById('main'))
      var option = {
        tooltip: {
          formatter(params) {
            const { companyName, cityName, price, score } = params?.data || {}
            if (params.data) return `${companyName || cityName + '分公司'}<br />销售额：${price}w<br />赠送积分：${score}w`
          }
        },
        geo: {
          map: 'china', //引入地图数据
          roam: true, //不开启缩放和平移
          zoom: 1.2, //视角缩放比例
          label: {
            show: true,
            fontSize: '10',
            color: '#fff'
          },
          emphasis: {
            label: {
              color: '#fff'
            },
            itemStyle: {
              areaColor: '#147a71',
              borderColor: '#24d2ad'
            }
          },
          itemStyle: {
            areaColor: '#133368',
            borderColor: 'rgba(255,255,255,0.2)'
          }
          // regions: [
          //   {
          //     name: this.$route.query.name,
          //     label: {
          //       normal: {
          //         show: true,
          //         fontSize: 12 * 1,
          //         color: "#ffff",
          //       },
          //     },
          //     itemStyle: {
          //       normal: {
          //         areaColor: "rgb(60,212,149)",
          //       },
          //     },
          //   },
          // ],
        },
        // 鼠标悬浮提示框
        series: [
          {
            type: 'map',
            geoIndex: 0,
            data: dataList
          }
        ]
      }
      myChart.setOption(option)
      myChart.on('click', function ({ data }) {
        // if (data.price == 0 && data.score == 0) return;
        if (data) self.$emit('goDown', data)
      })
    }
  },
  computed: {
    ...mapState({
      data: state => state.resData
    })
  }
}
</script>
