<template>
  <div class="main" v-show="!this.$route.query.cityId">
    <p>实时订单</p>
    <div class="scroll">
      <vueSeamlessScroll
        :data="listData"
        class="scroll-table"
        :class-option="defaultOption"
      >
        <div class="table" v-for="(item, index) in listData" :key="index">
          <span>{{ item.companyName }}</span>
          <span>{{ item.storeName }}</span>
          <span>{{ item.orderName }}</span>
          <span>￥{{ item.price }}</span>
        </div>
      </vueSeamlessScroll>
    </div>
  </div>
</template>

<script>
import vueSeamlessScroll from "vue-seamless-scroll";
import { mapState } from "vuex";
export default {
  name: "RealTimeOrder",
  data() {
    return {
      defaultOption: {
        step: 0.4, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 this.data.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      },
      listData: [],
    };
  },
  // 需要监视数据 防止图表切换数据没有及时更新
  watch: {
    data: {
      handler(newV) {
        this.listData = newV || [];
      },
    },
  },
  computed: {
    ...mapState({
      data: (state) => state.resData.nowOrder,
    }),
  },
  components: {
    vueSeamlessScroll,
  },
};
</script>

<style scoped>
.main {
  width: 100%;
  height: 200px;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.2);
  margin-top: 66px;
}
.main p {
  color: aqua;
  font-size: 18px;
  margin: 8px 0;
}
.main .scroll,
.scroll-table {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.table {
  display: flex;
}
.table span {
  color: #fff;
  flex: 1;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 10px 0;
}
</style>
