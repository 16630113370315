<template>
  <div id="SaleInfo" >
    <div class="info">
      <p class="title">{{ data.price || provResData.price || 0 }}</p>
      <p class="desc">总销售额（万）</p>
    </div>
    <div class="info">
      <p class="title">{{ data.score || provResData.score || 0 }}</p>
      <p class="desc">赠送积分（万）</p>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "SaleInfo",
  data() {
    return {
      isShow: false,
    };
  },
  watch: {
    data: {
      handler() {
        this.isShow = true;
      },
    },
  },
  computed: {
    ...mapState({
      data: (state) => state.resData,
      provResData: (state) => state.provResData,
    }),
  },
};
</script>

<style scoped>
#SaleInfo {
  margin-top: 22%;
}
.info {
  margin: 12px 0;
}
p {
  margin: 4px 0;
}
.title {
  font-size: 32px;
  color: #ffffff;
}
.desc {
  font-size: 18px;
  color: #ffffff;
}
</style>
