<template>
  <div>
    <div class="back">
      <h2>{{ $route.query.cityName }}分公司</h2>
      <!-- <span @click="goUp">返回</span> -->
    </div>
    <div id="main" style="height: 500px; width: 700px; margin: auto"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { mapState, mapActions } from "vuex";
export default {
  watch: {
    provResData() {
      const { cityId, cityName } = this.$route.query || {};
      const jsonData = import("@/mapJson/geometryProvince/" + cityId + ".json");
      jsonData.then((res) => {
        echarts.registerMap(cityName, res);
        this.initEchart(this.$route.query);
      });
    },
  },
  created() {
    this.getMapData(this.$route.query.cityId);
  },
  methods: {
    ...mapActions(["getMapData", "getProvMapData"]),
    goUp() {
      this.$emit("goUp");
    },
    // 操作接口数据
    updList(list) {
      return list.map((v) => {
        return {
          ...v,
          name: v.cityName,
        };
      });
    },
    initEchart({ cityName }) {
      // 防止渲染过快 接口还未请求成功
      if (!this.provResData) return;
      const returnData = this.provResData.list;
      var myChart = echarts.init(document.getElementById("main"));
      var option = {
        tooltip: {
          //数据格式化
          formatter(params) {
            const { name, storePrice, storeScore } = params.data;
            return `${name}<br />销售额：${storePrice || 0} w<br />赠送积分：${
              storeScore || 0
            } w`;
          },
        },
        geo: {
          map: cityName, //引入地图数据
          roam: true, //不开启缩放和平移
          zoom: 1.2, //视角缩放比例
          label: {
            show: true,
            fontSize: "10",
            color: "#fff",
          },
          emphasis: {
            label: {
              color: "#fff",
            },
            itemStyle: {
              borderColor: "#FFF",
              borderWidth: 1,
              shadowBlur: 10,
              shadowColor: "133877",
            },
          },
          itemStyle: {
            areaColor: "#133368",
            borderColor: "#e8e8e8",
          },
        },
        series: [
          // 散点图 为了给地图标注icon
          {
            type: "scatter",
            coordinateSystem: "geo",
            animation: false, //坐标点是否显示动画
            symbol: "pin",
            rippleEffect: {
              brushType: "fill", // stroke|fill
            },
            symbolSize: function () {
              //坐标点大小
              return 30;
            },
            data: returnData.map((itemOpt) => {
              console.log(1, itemOpt)
              return {
                ...itemOpt,
                // value: [
                //   parseFloat(itemOpt.longitude), //经度
                //   parseFloat(itemOpt.latitude), //维度
                // ],
                value: [
                  itemOpt.longitude, //经度
                  itemOpt.latitude, //维度
                ],
                itemStyle: {
                  //地图区域的多边形
                  color: "green", //坐标点颜色
                  shadowBlur: 10, // 图形阴影的模糊大小
                  shadowOffsetX: 10, // 阴影水平方向上的偏移距离。
                },
              };
            }),
          },
        ],
      };
      myChart.setOption(option);
    },
  },
  computed: {
    ...mapState({
      data: (state) => state.resData,
      provResData: (state) => state.provResData,
    }),
  },
};
</script>

<style scoped>
.back {
  display: flex;
  justify-content: space-between;
}
.back h2 {
  flex: 1;
  color: #fff;
  text-align: center;
}
.back span {
  text-align: right;
  color: #fff;
  cursor: pointer;
}
</style>
