<template>
  <resize class="headcontent">
    <div class="foundation">
      <Founddata />
      <Information />
      <Team />
    </div>
    <div class="map">
      <CenterMap />
    </div>
    <div class="shopping">
      <!-- <Ranking /> -->
      <MallData />
      <BrandMall />
      <Newly />
    </div>
  </resize>
</template>

<script>
import Resize from '@/components/Resize'
import Founddata from "./components/Founddata.vue";
import Information from "./components/Information.vue";
import Team from "./components/Team.vue";
// import Ranking from "./components/Ranking.vue";
import MallData from "./components/MallData.vue";
import BrandMall from "./components/BrandMall.vue";
import Newly from "./components/Newly.vue";
import CenterMap from "../CenterMap/index.vue";

export default {
  name: "Homwpage",
  components: {
    Resize,
    Founddata,
    Information,
    Team,
    // Ranking,
    MallData,
    BrandMall,
    Newly,
    CenterMap,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.foundation {
  display: flex;
  flex: 0 1 36%;
  -webkit-box-flex: 0 1 36%;
  position: relative;
}
.map {
  flex: 0 1 56%;
  padding: 0 3%;
  -webkit-box-flex: 0 1 56%;
}
.shopping {
  position: relative;
  flex: 0 1 36%;
  -webkit-box-flex: 0 1 36%;
}
</style>
