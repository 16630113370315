<template>
  <div class="newly">
    <div class="duptitle">新增用户</div>
    <div class="button-user">
      <span @click="ShowHidden = true" :class="{ extend: ShowHidden }"
        >分享达人</span
      >
      <span @click="ShowHidden = false" :class="{ ordinary: !ShowHidden }"
        >普通用户</span
      >
    </div>
    <div class="userdata">
      <!--  达人 -->
      <vueSeamlessScroll
        :data="RotationSwitching"
        :class-option="defaultOption"
        class="scroll"
      >
        <div
          class="user"
          v-for="(item, index) in RotationSwitching"
          :key="index"
        >
          <span>{{ item.nickname }}</span>
          <span class="user-con">{{ item.phone }}</span>
          <span>{{ item.createTime }}</span>
        </div>
      </vueSeamlessScroll>
      <!--  普通 -->
    </div>
  </div>
</template>
<script>
import { getNewOrders } from "@/services/basicdata";
import vueSeamlessScroll from "vue-seamless-scroll";
export default {
  name: "Newly",

  data() {
    return {
      defaultOption: {
        step: 0.4, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      },

      // 达人
      NewIntelligent: [],
      // 普通
      Newordinary: [],

      //显示与隐藏
      ShowHidden: true,
    };
  },
  components: {
    vueSeamlessScroll,
  },
  methods: {
    HighlightDom(e) {
      e.style.color = "#1e61a1";
      e.style.backgroundColor = "#ffffff";
    },
  },
  computed: {
    RotationSwitching() {
      if (this.ShowHidden) {
        return this.NewIntelligent;
      }
      return this.Newordinary;
    },
  },

  async created() {
    let NewOrders = await getNewOrders(0);
    let NewOrdersIntelligent = await getNewOrders(1);
    this.Newordinary = NewOrders;
    this.NewIntelligent = NewOrdersIntelligent;
  },
  watch: {},
};
</script>
<style lang="less" scoped>
.newly {
  position: absolute;
  top: 69%;
  left: 0;
  width: 100%;
  height: 29%;
  .button-user {
    float: right;
    width: 156px;
    height: 33px;
    background-color: #898989;
    margin-right: 2%;
    border-radius: 20px 20px;
    cursor: pointer;

    span {
      width: 50%;
      height: 100%;
      display: inline-block;
      font-size: 12px;
      color: #333333;
      text-align: center;
      line-height: 33px;
    }
  }
  .userdata {
    overflow: hidden;
    width: 100%;
    height: 86%;
    padding: 6%;
    .scroll {
      width: 100%;
      height: 100%;
      overflow: hidden;
      .user {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5%;
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        text-align: left;
        color: #ffffff;
        line-height: 16px;
        letter-spacing: 0.01px;
        width: 100%;
        span {
          display: block;
          &:nth-child(1) {
            width: 25%;
          }
          &:nth-child(2) {
            width: 30%;
          }
          &:nth-child(3) {
            width: 45%;
          }
        }
        .user-con {
          text-align: left;
        }
      }
    }
  }
  .extend {
    color: #1e61a1 !important;
    background-color: #ffffff;
    border-radius: 20px 0 0 20px;
  }
  .ordinary {
    color: #1e61a1 !important;
    background-color: #ffffff;
    border-radius: 0 20px 20px 0;
  }
}
</style>