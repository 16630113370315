<template>
  <div class="information">
    <div class="duptitle">基础数据</div>
    <div class="information-box">
      <div class="information-text">
        <div class="quantity">{{ ForchantData.companyNum }}</div>
        <span class="Subtitle">分公司</span>
      </div>
      <div class="information-text">
        <div class="quantity">{{ ForchantData.storeNum }}</div>
        <span class="Subtitle">城市合作商</span>
      </div>
      <div class="information-text">
        <div class="quantity">{{ ForchantData.talentNum }}</div>
        <span class="Subtitle">分享达人</span>
      </div>
      <div class="information-text">
        <div class="quantity">{{ ForchantData.userNum }}</div>
        <span class="Subtitle">用户数</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getformData } from "@/services/basicdata";
export default {
  name: "Founddata",
  data() {
    return {
      ForchantData: [],
    };
  },
  async created() {
    let FormData = await getformData();
    this.ForchantData = FormData;
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.information {
  position: absolute;
  top: 9%;
  // top: 0;
  left: 0;
  width: 100%;
  height: 29%;
  .information-box {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 100%;
    height: 86%;
    .information-text {
      flex: 50%;
      margin: auto;
      text-align: center;
      line-height: 1.5;
    }
  }
}
</style>