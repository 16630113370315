/**
 * 屏幕适配 mixin 函数
 */
// * 默认缩放值
const scale = {
  width: '1',
  height: '1',
}
// * 设计稿尺寸（px）
// const baseWidth = 2210
const baseWidth = 1920
const baseHeight = 1080
// * 需保持的比例（默认1.77778）
const baseProportion = parseFloat((baseWidth / baseHeight).toFixed(5))
const drawMixin = {
  data() {
    return {
      // * 定时函数
      drawTiming: null
    }
  },
  mounted() {
    this.calcRate()
    window.addEventListener('resize', this.resize)
  },
  unMounted() {
    window.removeEventListener('resize', this.resize)
  },
  methods: {
    calcRate() {
      const appRef = this.$refs['appRef']
      console.log('appRef=', appRef)
      if (!appRef) return
      // 当前宽高比
      const currentRate = parseFloat((window.innerWidth / window.innerHeight).toFixed(5))
      if (appRef) {
        if (currentRate > baseProportion) {
          // 表示更宽
          scale.width = ((window.innerHeight * baseProportion) / baseWidth).toFixed(5)
          scale.height = (window.innerHeight / baseHeight).toFixed(5)
          appRef.style.transform = `scale(${scale.width}, ${scale.height}) translate(-50%, -50%)`
        } else {
          // 表示更高
          scale.height = ((window.innerWidth / baseProportion) / baseHeight).toFixed(5)
          scale.width = (window.innerWidth / baseWidth).toFixed(5)
          appRef.style.transform = `scale(${scale.width}, ${scale.height}) translate(-50%, -50%)`
        }
      }
    },
    resize() {
      clearTimeout(this.drawTiming)
      this.drawTiming = setTimeout(() => {
        this.calcRate()
      }, 200)
    }
  }
}

/**
 * 屏幕适配 mixin 函数 autoResize
 */
// function randomExtend(minNum, maxNum) {
//   if (arguments.length === 1) {
//     return parseInt(Math.random() * minNum + 1, 10)
//   } else {
//     return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10)
//   }
// }
function debounce(delay, callback) {
  let lastTime
  return function () {
    clearTimeout(lastTime)
    const [that, args] = [this, arguments]
    lastTime = setTimeout(() => {
      callback.apply(that, args)
    }, delay)
  }
}
function observerDomResize(dom, callback) {
  const MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver
  const observer = new MutationObserver(callback)
  observer.observe(dom, { attributes: true, attributeFilter: ['style'], attributeOldValue: true })
  return observer
}
// function getPointDistance(pointOne, pointTwo) {
//   const minusX = Math.abs(pointOne[0] - pointTwo[0])
//   const minusY = Math.abs(pointOne[1] - pointTwo[1])
//   return Math.sqrt(minusX * minusX + minusY * minusY)
// }
// function uuid(hasHyphen) {
//   return (hasHyphen ? 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx' : 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx').replace(/[xy]/g, function (c) {
//     const r = Math.random() * 16 | 0
//     const v = c == 'x' ? r : (r & 0x3 | 0x8)
//     return v.toString(16)
//   })
// }
const autoResize = {
  data() {
    return {
      dom: '',
      width: 0,
      height: 0,
      debounceInitWHFun: '',
      domObserver: ''
    }
  },
  methods: {
    async autoResizeMixinInit() {
      const { initWH, getDebounceInitWHFun, bindDomResizeCallback, afterAutoResizeMixinInit } = this
      await initWH(false)
      getDebounceInitWHFun()
      bindDomResizeCallback()
      if (typeof afterAutoResizeMixinInit === 'function') afterAutoResizeMixinInit()
    },
    initWH(resize = true) {
      const { $nextTick, $refs, ref, onResize } = this
      return new Promise(resolve => {
        // eslint-disable-next-line no-unused-vars
        $nextTick(_ => {
          const dom = this.dom = $refs[ref]
          this.width = dom ? dom.clientWidth : 0
          this.height = dom ? dom.clientHeight : 0
          if (!dom) {
            console.warn('DataV: Failed to get dom node, component rendering may be abnormal!')
          } else if (!this.width || !this.height) {
            console.warn('DataV: Component width or height is 0px, rendering abnormality may occur!')
          }
          if (typeof onResize === 'function' && resize) onResize()
          resolve()
        })
      })
    },
    getDebounceInitWHFun() {
      const { initWH } = this
      this.debounceInitWHFun = debounce(100, initWH)
    },
    bindDomResizeCallback() {
      const { dom, debounceInitWHFun } = this
      this.domObserver = observerDomResize(dom, debounceInitWHFun)
      window.addEventListener('resize', debounceInitWHFun)
    },
    unbindDomResizeCallback() {
      let { domObserver, debounceInitWHFun } = this
      if (!domObserver) return
      domObserver.disconnect()
      domObserver.takeRecords()
      domObserver = null
      window.removeEventListener('resize', debounceInitWHFun)
    }
  },
  mounted() {
    const { autoResizeMixinInit } = this
    autoResizeMixinInit()
  },
  beforeDestroy() {
    const { unbindDomResizeCallback } = this
    unbindDomResizeCallback()
  }
}

export {
  drawMixin,
  autoResize
}