<template>
  <div class="center-map">
    <SaleInfo />
    <template v-if="chinaMapShow">
      <ChinaMap @goDown="goDown" />
      <RealTimeOrder />
    </template>
    <ProvMap @goUp="goUp" v-else />
  </div>
</template>

<script>
import SaleInfo from "./components/SaleInfo.vue";
import ChinaMap from "./components/ChinaMap.vue";
import ProvMap from "./components/ProvMap.vue";
import RealTimeOrder from "./components/RealTimeOrder.vue";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      chinaMapShow: true,
      provData: null,
    };
  },
  created() {
    const { cityId } = this.$route.query;
    !cityId && this.getMapData(cityId);
    cityId && this.getProvMapData(cityId);
    if (cityId) this.chinaMapShow = false;
  },
  methods: {
    ...mapActions(["getMapData", "getProvMapData"]),
    goDown(data) {
      data.cityId = data.cityId.toString().substring(0, 2);
      this.getMapData(data.cityId);
      if (data.cityId) {
        this.$router.push({
          path: "province",
          query: {
            cityId: data.cityId,
            cityName: data.name,
          },
        });
        // this.getProvMapData(data.cityId);
      }
      this.chinaMapShow = false;
    },
    goUp() {
      this.chinaMapShow = true;
      // console.log();
      this.$router.replace({
        name: "index",
      });
      this.getMapData(this.$route.query.cityId);
    },
  },
  components: {
    SaleInfo,
    ChinaMap,
    ProvMap,
    RealTimeOrder,
  },
};
</script>

<style scoped>
.center-map {
  position: relative;
  width: 100%;
}
</style>
