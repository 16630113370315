<template>
  <div class="information">
    <div class="duptitle">商户信息</div>
    <div class="information-box">
      <div class="information-text">
        <div class="quantity">{{ hotel }}</div>
        <span class="Subtitle">婚庆酒店</span>
      </div>
      <div class="information-text">
        <div class="quantity">{{ plan }}</div>
        <span class="Subtitle">婚庆策划</span>
      </div>
      <div class="information-text">
        <div class="quantity">{{ photographicStudio }}</div>
        <span class="Subtitle">婚庆影楼</span>
      </div>
      <div class="information-text">
        <div class="quantity">{{ business }}</div>
        <span class="Subtitle">联盟商家</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getMerchantInformation } from "@/services/basicdata";
export default {
  name: "Information",

  data() {
    return {
      hotel: 0,
      plan: 0,
      photographicStudio: 0,
      business: 0,
      MerchantData: [],
    };
  },
  async created() {
    const provinceId = this.$route.query.cityId + '0000'
    let Merchant = await getMerchantInformation({ provinceId });
    this.MerchantData = Merchant;
  },
  methods: {},

  watch: {
    MerchantData: {
      handler(val) {
        this.hotel = 0;
        this.plan = 0;
        this.photographicStudio = 0;
        this.business = 0;
        val.forEach((item) => {
          switch (item.typeName) {
            case "联盟商家":
              this.business = item.storeNum;
              break;
            case "婚宴酒店":
              this.hotel = item.storeNum;
              break;
            case "婚庆策划":
              this.plan = item.storeNum;
              break;
            case "婚纱影楼":
              this.photographicStudio = item.storeNum;
              break;
          }
        });
      },
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped>
.information {
  position: absolute;
  top: 39%;
  left: 0;
  width: 100%;
  height: 29%;
  .information-box {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 100%;
    height: 86%;
    .information-text {
      flex: 50%;
      margin: auto;
      text-align: center;
      line-height: 1.5;
    }
  }
}
</style>