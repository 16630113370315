<template>
  <div class="information">
    <div class="duptitle">积分商城</div>
    <div class="information-box">
      <div class="information-text">
        <div class="quantity">{{ MerRderData.orderNum }}</div>
        <span class="Subtitle">积分兑换（万）</span>
      </div>
      <div class="information-text">
        <div class="quantity">{{ MerRderData.price }}</div>
        <span class="Subtitle">销量</span>
      </div>
      <div class="information-text">
        <div class="quantity">{{ MerRderData.avgPrice }}</div>
        <span class="Subtitle">人均积分</span>
      </div>
      <div class="information-text">
        <div class="quantity">{{ MerRderData.nextNum }}</div>
        <span class="Subtitle">近十分钟新增订单</span>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { getrderData } from "@/services/basicdata";
export default {
  name: "Founddata",

  data() {
    return {
      hotel: 0,
      plan: 0,
      photographicStudio: 0,
      business: 0,
      MerRderData: {},
    };
  },
  async created() {
    let RderData = await getrderData();
    this.MerRderData = RderData[0];
  },
  methods: {},
};
</script>
  
  <style lang="less" scoped>
.information {
  position: absolute;
  top: 9%;
  left: 0;
  width: 100%;
  height: 29%;
  .information-box {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 100%;
    height: 86%;
    .information-text {
      flex: 50%;
      margin: auto;
      text-align: center;
      line-height: 1.5;
    }
  }
}
</style>