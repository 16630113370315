<template>
  <div class="information">
    <div class="duptitle">品牌商城</div>
    <div class="information-box">
      <div class="information-text">
        <div class="quantity">{{ MerchantData.orderNum }}</div>
        <span class="Subtitle">订单总额（万）</span>
      </div>
      <div class="information-text">
        <div class="quantity">{{ MerchantData.price }}</div>
        <span class="Subtitle">销量</span>
      </div>
      <div class="information-text">
        <div class="quantity">{{ MerchantData.avgPrice }}</div>
        <span class="Subtitle">人均消费（元）</span>
      </div>
      <div class="information-text">
        <div class="quantity">{{ MerchantData.nextNum }}</div>
        <span class="Subtitle">近十分钟新增订单</span>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { getrderData } from "@/services/basicdata";
export default {
  name: "BrandMall",

  data() {
    return {
      hotel: 0,
      plan: 0,
      photographicStudio: 0,
      business: 0,
      MerchantData: {},
    };
  },
  async created() {
    let RderData = await getrderData();
    this.MerchantData = RderData[1];
  },
  methods: {},

  watch: {
    // MerchantData: {
    //   handler(val) {
    //     this.hotel = 0;
    //     this.plan = 0;
    //     this.photographicStudio = 0;
    //     this.business = 0;
    //     val.forEach((item) => {
    //       switch (item.typeName) {
    //         case "联盟商家":
    //           this.business = item.storeNum;
    //           break;
    //         case "婚宴酒店":
    //           this.hotel = item.storeNum;
    //           break;
    //         case "婚庆策划":
    //           this.plan = item.storeNum;
    //           break;
    //         case "婚纱影楼":
    //           this.photographicStudio = item.storeNum;
    //           break;
    //       }
    //     });
    //   },
    //   deep: true,
    // },
  },
};
</script>
  
  <style lang="less" scoped>
.information {
  position: absolute;
  top: 39%;
  left: 0;
  width: 100%;
  height: 29%;
  .information-box {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 100%;
    height: 86%;
    .information-text {
      flex: 50%;
      margin: auto;
      text-align: center;
      line-height: 1.5;
    }
  }
}
</style>