<template>
  <div class="data">
    <div class="duptitle-box">
      <div class="duptitle">基础数据</div>
      <div 
        v-if="isShowBack"
        class="back-box"
      >
        <span class="back-img"><img src="@/assets/back2.jpg" /></span>
        <span @click="handleBack">返回</span>
      </div>
    </div>
    <div class="data-content">
      <div class="data-partner">
        <div>
          <div class="quantity">{{ BasicUser.storeNum }}</div>
          <span class="Subtitle">城市合作商</span>
        </div>
      </div>
      <div class="data-partner">
        <div>
          <div class="quantity">{{ BasicUser.talentNum }}</div>
          <span class="Subtitle">分享达人</span>
        </div>
      </div>
      <div class="data-partner">
        <div>
          <div class="quantity">{{ BasicUser.userNum }}</div>
          <span class="Subtitle">用户数</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getBasicData } from "@/services/basicdata.js";
export default {
  name: "Founddata",
  data() {
    return {
      BasicUser: {},
      isShowBack: true
    };
  },
  async created() {
    const provinceId = this.$route.query.cityId + '0000'
    let BasicData = await getBasicData({ provinceId });
    this.BasicUser = BasicData;
    this.isShowBack = JSON.parse(localStorage.getItem('urlParams')).noBack === '1' ? false : true
  },
  methods: {
    handleBack() {
      this.$router.replace('/')
    }
  }
};
</script>

<style lang="less" scoped>
.data {
  position: absolute;
  top: 9%;
  left: 0;
  height: 29%;
  width: 100%;

  .duptitle-box {
    display: flex;
    justify-content: space-between;
  }

  .back-box {
    display: flex;
    align-items: center;
    color: #fff;
    cursor: pointer;
    margin-top: -5px;

    .back-img {
      width: 20px;
      height: 20px;
      border: 2px solid #fff;
      border-radius: 50%;
      overflow: hidden;
      position: relative;

      img {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 160%;
        height: 160%;
        transform: translate(-50%, -50%);
      }
    }

    span:last-child {
      // margin-left: 10px;
      margin: -1px 0 0 4px;
    }
  }

  .data-content {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 86%;

    .data-partner {
      flex: 1;
      display: flex;
      justify-content: space-around;
      align-items: center;
      text-align: center;
      line-height: 1.5;
    }
  }
}
</style>
