<template>
  <div class="ranking">
    <div class="duptitle dupstyle">商户销售TOP</div>
    <div class="ranking-con">
      <div class="icon-box">
        <i class="icon">
          <img
            :src="require('@/assets/ranking01.png')"
            alt=""
          />
        </i>
        <i class="icon">
          <img
            :src="require('@/assets/ranking02.png')"
            alt=""
          />
        </i>
        <i class="icon">
          <img
            :src="require('@/assets/ranking03.png')"
            alt=""
          />
        </i>
        <i class="icon">④</i>
        <i class="icon">⑤</i>
        <i class="icon">⑥</i>
        <i class="icon">⑦</i>
        <i class="icon">⑧</i>
        <i class="icon">⑨</i>
        <i class="icon">⑩</i>
      </div>
      <div class="main-box">
        <div
          class="ranking-list"
          v-for="(item, index) in saleRanking"
          :key="index"
        >
          <span class="winesname">{{ item.storeName }}</span>
          <span class="sales">{{ item.price }} 万</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getSalesChart } from '@/services/basicdata'

export default {
  name: "Ranking",
  data() {
    return {
      // 销售榜
      saleRanking: [],
      SalesData: [],
    }
  },
  async created() {
    const provinceId = this.$route.query.cityId + '0000'
    let Sales = await getSalesChart({ provinceId })
    this.SalesData = Sales
  },
  watch: {
    SalesData: {
      handler(val) {
        let newCompanyList = [];
        newCompanyList = [...val].sort((a, b) => b.price - a.price);
        this.saleRanking = newCompanyList.slice(0, 10);
      },
      deep: true,
    },
  },
};
</script>
<style lang="less" scoped>
.ranking {
  position: absolute;
  top: 9%;
  left: 0;
  width: 100%;
  height: 58%;
  .dupstyle {
    height: 7%;
  }
  .ranking-con {
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 93%;
    padding: 4% 8% 0 5%;
    text-align: center;
    .icon-box {
      display: flex;
      width: 15%;
      height: 100%;
      flex-direction: column;

      .icon {
        width: 36px;
        height: 36px;
        line-height: 36px;
        margin-bottom: 20%;
      }
    }
    .main-box {
      width: 90%;
      .ranking-list {
        display: flex;
        justify-content: space-between;
        height: 36px;
        line-height: 36px;
        margin-bottom: 13px;
        .winesname {
          overflow: hidden;
        }
      }
    }
    i,
    span {
      display: inline-block;
      font-size: 16px;
      color: #ffffff;
    }

    img {
      width: 36px;
      height: 36px;
      vertical-align: middle;
    }
  }
}
</style>
